import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proposal-popover',
  templateUrl: './proposal-popover.component.html',
  styleUrls: ['./proposal-popover.component.scss'],
})
export class ProposalPopoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
