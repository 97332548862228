import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PaymentControllerService {

  private urlNodes = environment.urlApi;

  constructor(
    private http: HttpClient,
  ) { }

  createdOrUpdated(payment: any) {
    return this.http.post(`${this.urlNodes}payments/createdOrUpdated`, payment).toPromise().then(data=>data);
  }

  getGigId(gigID: any) {
    return this.http.get(`${this.urlNodes}payments/gig/`+gigID+``).toPromise().then(data=>data);
  }

}
