import { UtilsService } from './../../Services/utils.service';
import { GetService } from './../../Services/get.service';
import { ModalController, NavParams, ToastController, ActionSheetController, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import * as moment from "moment"
import { Login } from 'src/app/interface/login';
import * as firebase from 'firebase';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss'],
})
export class ReceiptsComponent implements OnInit {

  public originName: any;
  public textok: boolean = false;
  private dateArtist: any;
  public infoUser: any = {};
  public user: Login = {}
  public blockUpdated: any;

  /* profile */
  public name: any = {}
  public lastName: any = {}
  public senha: any = ''
  public passwordNew: any = ''
  artistId = null;
  constructor(
    private modalController: ModalController,
    private navparams: NavParams,
    private serviceget: GetService,
    private actionSheetController: ActionSheetController,
    public utils: UtilsService,
    private alertController: AlertController,
  ) {
    let dados = this.navparams;
    this.infoUser = dados.data[0]
    this.infoUser = this.infoUser[0]
  }

  ngOnInit() {
    var names: any = this.infoUser.name.split(" ")
    this.name = names[0]
    this.lastName = names[1]

    this.checkArtist()
  }

  checkArtist() {
    this.serviceget.getAllArtist().subscribe((data: any) => {
      data = data.filter(filter => {
        return filter.user_id == this.infoUser.id
      })
      if (data.length == 0) {
        this.artistId = null
      } else {
        console.log(data)
        this.artistId = data[0].id
      }
    })
  }


  updatedUser() {
    if (['', undefined, null].includes(this.infoUser.name)) {
      this.utils.toast.presentToast("Digite o seu nome e sobrenome")
    } else if (['', undefined, null].includes(this.infoUser.phone)) {
      this.utils.toast.presentToast("Digite o telefone")
    } else if (['', undefined, null].includes(this.name)) {
      this.utils.toast.presentToast("Digite o seu nome")
    } else if (['', undefined, null].includes(this.lastName)) {
      this.utils.toast.presentToast("Digite o seu sobrenome")
    }
    else {
      this.utils.presentLoading()
      this.infoUser.name = "" + this.name + " " + this.lastName + ""
      let data = {
        email: this.infoUser.email,
        name: this.infoUser.name,
        photo: this.infoUser.photo,
        firebase_id: this.infoUser.firebase_id,
        password: this.infoUser.password,
        gender: this.infoUser.gender,
        phone: this.infoUser.phone,
        birth_date: this.infoUser.birth_date,
        email_verified_at: this.infoUser.email_verified_at,
        id: this.infoUser.id,
        pix_code: this.infoUser.pix_code,
        type_pix: this.infoUser.type_pix
      }

      this.serviceget.updateUser(data).subscribe(userResponse => {
        if (userResponse["message"] == 'User was updated successfully.') {
          this.infoUser = {};
          this.user = {};
          this.utils.dismissLoader()
          this.dismiss();
          this.utils.toast.presentToast("Atualizado com sucesso!")
        } else {
          this.utils.toast.presentToast("Não foi possível atualizar, tente mais tarde.")
          this.utils.dismissLoader()
        }
      })
    }
  }

  updatedPassword(password, newpassword) {
    if (password != this.infoUser.password) {
      this.utils.toast.presentToast("Não confere com a sua senha antiga.")
    } else if (password == newpassword) {
      this.utils.toast.presentToast("Nova senha não pode ser igual a antiga.")
    } else if (newpassword.length < 6) {
      this.utils.toast.presentToast("A senha precisa conter no mínimo 6 caracteres.")
    } else {
      this.utils.presentLoading()
      this.infoUser.name = "" + this.name + " " + this.lastName + ""
      let data = {
        email: this.infoUser.email,
        name: this.infoUser.name,
        photo: this.infoUser.photo,
        firebase_id: this.infoUser.firebase_id,
        password: newpassword,
        gender: this.infoUser.gender,
        phone: this.infoUser.phone,
        birth_date: this.infoUser.birth_date,
        email_verified_at: this.infoUser.email_verified_at,
        id: this.infoUser.id
      }
      this.serviceget.updateUser(data).subscribe(userResponse => {
        if (userResponse["message"] == 'User was updated successfully.') {
          this.infoUser = {};
          this.user = {};
          this.utils.dismissLoader()
          this.dismiss();
          this.utils.toast.presentToast("Senha tualizada com sucesso!")
        } else {
          this.utils.dismissLoader()
          this.utils.toast.presentToast("Não foi possível atualizar, tente mais tarde.")
        }
      })
    }
  }


  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }


}

