import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BrMaskerIonicServices3, BrMaskModel, BrMaskServicesModel } from 'brmasker-ionic-3';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class GetService {
  private urlNodes = environment.urlApi;

  constructor(
    private http: HttpClient,
    private brMasker: BrMaskerIonicServices3
  ) { }

  /* VIACEP */
  get(cep: any) {
    return new Observable((x) => {
      var request = new XMLHttpRequest();
      request.open('get', 'https://viacep.com.br/ws/' + cep + '/json/', true);
      request.send();
      request.onload = function () {
        var data = JSON.parse(this.response);
        x.next(data)
      }
      
    })
  }

  /* mask */
  maskPhone(valor: string) {
    const config: BrMaskModel = new BrMaskModel();
    config.phone = true;
    return this.brMasker.writeCreateValue(valor, config);
  }

  maskPrice(valor: string) {
    const config: BrMaskModel = new BrMaskModel();
    config.money = true;
    return this.brMasker.writeCreateValue(valor, config);
  }

  /* Get */

  getViewRelatorios(id) {
    return this.http.get(`${this.urlNodes}reports_artists/`+id+``);
  }
  gethelp() {
    return this.http.get(`${this.urlNodes}helps/`);
  }
  getUser() {
    return this.http.get(`${this.urlNodes}users/`);
  }
  getByIdUser(uid) {
    return this.http.get(`${this.urlNodes}users/${uid}`).toPromise().then(data => data);
  }
  getByIdUserFix(uid) {
    return this.http.get(`${this.urlNodes}users/id/${uid}`);
  }
  getAllArtist() {
    return this.http.get(`${this.urlNodes}artists/actives`);
  }
  getArtistByID(id) {
    return this.http.get(`${this.urlNodes}artists/` + id + ``);
  }
  getAllProposals() {
    return this.http.get(`${this.urlNodes}proposals/actives/`);
  }
  getProposalsByid(id: any) {
    return this.http.get(`${this.urlNodes}proposals/` + id + ``);
  }

  getCities() {
    return this.http.get(`${this.urlNodes}cities`);
  }
  getCitiesById(idCity: any) {
    console.log(this.http.get(`${this.urlNodes}cities/` + idCity + ``).toPromise().then(data => {
      return data;

    }));
    return this.http.get(`${this.urlNodes}cities/` + idCity + ``);
  }

  getGenre() {
    return this.http.get(`${this.urlNodes}genres`);
  }

  getProposalGenre() {
    return this.http.get(`${this.urlNodes}proposals_genres`);
  }

  getProposalCity() {
    return this.http.get(`${this.urlNodes}proposals_cities`);
  }

  getAbsencesRecurrent() {
    return this.http.get(`${this.urlNodes}absences/recurrent`);
  }

  getAbsencesOccasional() {
    return this.http.get(`${this.urlNodes}absences/occasional`);
  }

  getAbsencesRecurrentART(artist) {
    return this.http.get(`${this.urlNodes}absences/recurrent/artist/` + artist + ``);
  }

  getAbsencesOccasionalART(artist) {
    return this.http.get(`${this.urlNodes}absences/occasional/artist/` + artist + ``);
  }
  getAbsencesOccasionalViewART(artist) {
    return this.http.get(`${this.urlNodes}absences/recurrent/calendar/artist/` + artist + ``);
  }

  getGig() {
    return this.http.get(`${this.urlNodes}gigs`);
  }
  getByIdGig(id) {
    return this.http.get(`${this.urlNodes}gigs/` + id + ``);
  }
  getProposalByIdDetails(idproposal) {
    return this.http.get(`${this.urlNodes}proposal_details/proposalid/` + idproposal + ``);
  }

  getStatusById(gig_id: any) {
    return this.http.get(`${this.urlNodes}gig_situations/gigid/` + gig_id + ``);
  }

  getAllReviews(id) {
    return this.http.get(`${this.urlNodes}gigs/reviews/artist/` + id + ``).toPromise().then(data => data);;
  }

  getReviewsGigs(id) {
    return this.http.get(`${this.urlNodes}gigs/reviews/gig/` + id + ``).toPromise().then(data => data);
  }

  /* ADD */

  addArtist(artist: any) {
    return this.http.post(`${this.urlNodes}artists/`, artist);
  }
  addProposal(proporsal: any) {
    return this.http.post(`${this.urlNodes}proposals/`, proporsal);
  }
  addProposalGenres(proporsalGenres: any) {
    return this.http.post(`${this.urlNodes}proposals_genres`, proporsalGenres);
  }
  addProposalCity(proporsalCity: any) {
    return this.http.post(`${this.urlNodes}proposals_cities`, proporsalCity);
  }
  addAb(Ab: any) {
    return this.http.post(`${this.urlNodes}absences/recurrent`, Ab);
  }
  addOC(OC: any) {
    return this.http.post(`${this.urlNodes}absences/occasional`, OC);
  }
  addProposalDetails(proposal_details: any) {
    return this.http.post(`${this.urlNodes}proposal_details`, proposal_details);
  }
  addGigs(gigs: any) {
    return this.http.post(`${this.urlNodes}gigs`, gigs);
  }
  addGigStatus(gig_situations: any) {
    return this.http.post(`${this.urlNodes}gig_situations`, gig_situations);
  }
  addGigOrden(gigs_orders: any) {
    return this.http.post(`${this.urlNodes}gigs_orders`, gigs_orders);
  }
  addUser(user: any) {
    return this.http.post(`${this.urlNodes}users`, user);
  }

  addReview(review: any) {
    return this.http.post(`${this.urlNodes}gigs/reviews`, review).toPromise().then(data => data);
  }
  /* Update */

  updateArtist(artist: any) {
    return this.http.put(`${this.urlNodes}artists/`, artist);
  }

  updateUser(artist: any) {
    return this.http.put(`${this.urlNodes}firebase/` + artist.id + ``, artist);
  }

  updateProposal(proporsal: any) {
    return this.http.put(`${this.urlNodes}proposals/`, proporsal);
  }

  updateOC(oc: any) {
    return this.http.put(`${this.urlNodes}absences/occasional/`, oc);
  }

  updateAB(ab: any) {
    return this.http.put(`${this.urlNodes}absences/recurrent/`, ab);
  }

  updateDetailsProposal(proposalDetails: any) {
    return this.http.put(`${this.urlNodes}proposal_details`, proposalDetails);
  }


  inactived(id, artist) {
    return this.http.put(`${this.urlNodes}inactived/` + id + `/` + artist + ``, {}).toPromise().then(data=>data);
  }

  enviar(formdata: FormData) {
    return this.http.post(`https://ondeassistir.org/upload.php`, formdata);
  }

  /* Delete */

  deleteProporsal(proporsal: any) {
    let proposta = proporsal;

    proposta['inactiveAt'] = new Date();

    console.log(proposta);
    return this.http.put(`${this.urlNodes}proposals/` + ``, proposta);
  }

  deleteProporsalGenre(proporsal: any) {
    if (proporsal.length >= 1) {
      var remove = []
      for (let i = 0; i < proporsal.length; i++) {
        remove.push(proporsal[i].id_favorite)
      }
    } else {
      remove = proporsal.id_favorite
      remove = remove ? remove : proporsal.id
    }
    console.log(remove, proporsal)
    return this.http.delete(`${this.urlNodes}proposals_genres/` + remove + ``,);
  }

  deleteProporsalCities(proporsal: any) {
    return this.http.delete(`${this.urlNodes}proposals_cities/` + proporsal.id + ``,);
  }

  deletePause(id: any) {
    return this.http.delete(`${this.urlNodes}absences/recurrent/` + id + ``,);
  }

  deletePauseOC(id: any) {
    return this.http.delete(`${this.urlNodes}absences/occasional/` + id + ``,);
  }
  deleteGig(id: any) {
    return this.http.delete(`${this.urlNodes}gigs/` + id + ``,);
  }

  deleteGigStatus(id: any) {
    return this.http.delete(`${this.urlNodes}gig_situations/` + id + ``,);
  }


  /* Disputes */
  getStatusDispute() {
    return this.http.get(`${this.urlNodes}status_disputes`).toPromise().then(data => data).catch(err => err);
  }

  getReason() {
    return this.http.get(`${this.urlNodes}reason_disputes`).toPromise().then(data => data).catch(err => err);
  }

  getDisputes(id: any) {
    return this.http.get(`${this.urlNodes}disputes/gig/` + id + ``).toPromise().then(data => data).catch(err => err);
  }


  getDisputesComments(id: any) {
    return this.http.get(`${this.urlNodes}disputes_comments/dispute/` + id + ``).toPromise().then(data => data).catch(err => err);
  }

  addDispute(artist: any) {
    return this.http.post(`${this.urlNodes}disputes`, artist).toPromise().then(data => data).catch(err => err);;
  }

  addDisputeSituations(artist: any) {
    return this.http.post(`${this.urlNodes}disputes_situations`, artist).toPromise().then(data => data).catch(err => err);;
  }

  addDisputeComments(artist: any) {
    return this.http.post(`${this.urlNodes}disputes_comments`, artist).toPromise().then(data => data).catch(err => err);;
  }






}