import { UtilsService } from './../../Services/utils.service';
import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-animation',
  templateUrl: './animation.page.html',
  styleUrls: ['./animation.page.scss'],
})
export class AnimationPage implements OnInit {

  constructor(
    public utils: UtilsService,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    setTimeout(() => {
       this.firebase();
       this.modalCtrl.dismiss();
    }, 3000);
  }

  firebase() {
    firebase.auth().onAuthStateChanged((usersss) => {
      if (usersss) {
        this.utils.router.navigate(["/list-home"]);
      } else {
        this.utils.router.navigate(["/login"]);
      }
    })
  }

}
