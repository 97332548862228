import { LoaderCustomComponent } from './pages/utilities/loader-custom/loader-custom.component';
import { UtilsService } from './Services/utils.service';

import { Component, NgZone, OnInit } from '@angular/core';

import { Platform, MenuController, AlertController, ModalController, ActionSheetController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import * as firebase from 'firebase';
import { GetService } from './Services/get.service';
import { environment } from 'src/environments/environment.prod';
import { AnimationPage } from './pages/animation/animation.page';
import { MenuService } from './Services/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public userById: any;
  public selectedIndex = 1;
  public appPages = [];
  public labels = ['Sair'];
  public user: any;
  public image: string = 'assets/templates/user/profile.png';
  infoUser: any = [];
  isAuthorized: boolean = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public menuCtrl: MenuController,
    public serviceget: GetService,
    private alertController: AlertController,
    private actionSheetController: ActionSheetController,
    public utils: UtilsService,
    private zone: NgZone,
    private modalCtrl: ModalController,
    private menuService: MenuService
  ) {
    this.presentModal();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      //  this.splashScreen.hide();
    });
  }


  ngOnInit() {
    this.firebaseInit()

  }

  firebaseInit() {
    firebase.auth().onAuthStateChanged((usersss) => {
      console.log(usersss)

      if (usersss) {
        this.utils.firebaseid = usersss.uid
        this.serviceget.getByIdUser(usersss.uid).then((getId: any) => {
          console.log(getId)
          if (getId[0] && !getId[0]['inactiveAt']) {
            this.infoUser = getId;
            this.userById = getId ? getId : null;
            if (this.userById.length == 0) {
              this.ngOnInit();
            } else {
              this.menuCtrl.enable(true);
              this.utils.photoProfile = this.userById[0].photo
              this.initMenu(this.userById[0].perfil);
            }
          } else {
            this.inactivedAlert()
          }
        })
      } else {
        this.utils.router.navigate(["/login"]);
      }
    })
  }

  async inactivedAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Sua conta está inativada',
      subHeader: 'Você desativou sua conta, para ativar entre em contato com o suporte.',
      buttons: [
        {
          text: 'Confirmar',
          handler: (data) => {
            this.forceForwad()
          }
        }
      ]
    });

    await alert.present();
  }

  forceForwad() {
    this.utils.router.navigate(["/login"])
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      this.utils.router.navigate(["/login"])
    }).catch((error) => {
      console.log(error)
    });
  }

  initMenu(profile) {
    let menus = this.menuService.listMenus() ? this.menuService.listMenus() : null;

    switch (profile) {
      case 'Usuário':
        this.zone.run(() => {
          this.appPages = menus.filter(e => e.title != 'Perfil' && e.title != "Relatórios");
          this.utils.animation = true;
        });
        break;

      default:
        this.zone.run(() => {
          this.appPages = menus;
          this.utils.animation = true;
        });
        break;
    }
  }

  clickLabel() {
    this.logoutAlert('Deseja mesmo sair?');
  }

  async logoutAlert(message) {
    const alert = await this.alertController.create({
      header: 'Você está saindo do aplicativo',
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'danger',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Sim',
          handler: () => {
            this.logout()
          }
        }
      ]
    });
    return alert.present();
  }


  logout() {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      this.utils.router.navigate(["/login"])
    }).catch((error) => {
      console.log(error)
    });

  }

  updatedPhoto() {
    this.utils.presentLoading()
    this.infoUser = this.infoUser[0]
    let data = {
      email: this.infoUser.email,
      name: this.infoUser.name,
      photo: "",
      firebase_id: this.infoUser.firebase_id,
      password: this.infoUser.password,
      gender: this.infoUser.gender,
      phone: this.infoUser.phone,
      birth_date: this.infoUser.birth_date,
      email_verified_at: this.infoUser.email_verified_at,
      id: this.infoUser.id,
    }
    this.serviceget.updateUser(data).subscribe(userResponse => {
      console.log(userResponse)
      if (userResponse["message"] == 'User was updated successfully.') {
        this.infoUser = {};
        this.user = {};
        this.utils.photoProfile = ""
        this.utils.dismissLoader()
      } else {
        this.utils.dismissLoader()
      }
    })
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Albums',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Tirar foto',
        icon: 'camera-outline',
        handler: () => {
          this.utils.openCamera("Tirar foto");
        }
      }, {
        text: 'Escolher da galeria',
        icon: 'image-outline',
        handler: () => {
          this.utils.openCamera("Galeria");
        }
      }, {
        cssClass: 'danger',
        text: 'Remover foto',
        icon: 'close-outline',
        handler: () => {
          this.updatedPhoto()
        }
      }, {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();

    const { role, data } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role and data', role, data);
  }

  async presentModal() {
    const modal = await this.modalCtrl.create({
      component: AnimationPage,
      cssClass: 'my-custom-class',
      componentProps: {
        'firstName': 'Douglas',
        'lastName': 'Adams',
        'middleInitial': 'N'
      }
    });
    return await modal.present();
  }

}