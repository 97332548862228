
import { UtilsService } from './../../Services/utils.service';
import { GetService } from './../../Services/get.service';
import { ModalController, NavParams, ToastController, ActionSheetController, AlertController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import * as moment from "moment"
import { Login } from 'src/app/interface/login';
import * as firebase from 'firebase';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  public originName: any;
  public textok: boolean = false;
  private dateArtist: any;
  public infoUser: any = {};
  public user: Login = {}
  public blockUpdated: any;

  /* profile */
  public name: any = {}
  public lastName: any = {}
  public senha: any = ''
  public passwordNew: any = ''
  artistId = null;
  constructor(
    private modalController: ModalController,
    private navparams: NavParams,
    private serviceget: GetService,
    private actionSheetController: ActionSheetController,
    public utils: UtilsService,
    private alertController: AlertController,
  ) {
    let dados = this.navparams;
    this.infoUser = dados.data[0]
    this.infoUser = this.infoUser[0]

  }

  ngOnInit() {
    var names: any = this.infoUser.name.split(" ")
    this.name = names[0]
    this.lastName = names[1]

    this.checkArtist()
  }

  checkArtist() {
    this.serviceget.getAllArtist().subscribe((data: any) => {
      data = data.filter(filter => {
        return filter.user_id == this.infoUser.id
      })
      if (data.length == 0) {
        this.artistId = null
      } else {
        console.log(data)
        this.artistId = data[0].id
      }
    })
  }


  updatedUser() {
    if (['', undefined, null].includes(this.infoUser.name)) {
      this.utils.toast.presentToast("Digite o seu nome e sobrenome")
    } else if (['', undefined, null].includes(this.infoUser.phone)) {
      this.utils.toast.presentToast("Digite o telefone")
    } else if (['', undefined, null].includes(this.name)) {
      this.utils.toast.presentToast("Digite o seu nome")
    } else if (['', undefined, null].includes(this.lastName)) {
      this.utils.toast.presentToast("Digite o seu sobrenome")
    }
    else {
      this.utils.presentLoading()
      this.infoUser.name = "" + this.name + " " + this.lastName + ""
      let data = {
        email: this.infoUser.email,
        name: this.infoUser.name,
        photo: this.infoUser.photo,
        firebase_id: this.infoUser.firebase_id,
        password: this.infoUser.password,
        gender: this.infoUser.gender,
        phone: this.infoUser.phone,
        birth_date: this.infoUser.birth_date,
        email_verified_at: this.infoUser.email_verified_at,
        id: this.infoUser.id,
      }

      this.serviceget.updateUser(data).subscribe(userResponse => {
        if (userResponse["message"] == 'User was updated successfully.') {
          this.infoUser = {};
          this.user = {};
          this.utils.dismissLoader()
          this.dismiss();
          this.utils.toast.presentToast("Atualizado com sucesso!")
        } else {
          this.utils.toast.presentToast("Não foi possível atualizar, tente mais tarde.")
          this.utils.dismissLoader()
        }
      })
    }
  }

  updatedPassword(password, newpassword) {
    if (password != this.infoUser.password) {
      this.utils.toast.presentToast("Não confere com a sua senha antiga.")
    } else if (password == newpassword) {
      this.utils.toast.presentToast("Nova senha não pode ser igual a antiga.")
    } else if (newpassword.length < 6) {
      this.utils.toast.presentToast("A senha precisa conter no mínimo 6 caracteres.")
    } else {
      this.utils.presentLoading()
      this.infoUser.name = "" + this.name + " " + this.lastName + ""
      let data = {
        email: this.infoUser.email,
        name: this.infoUser.name,
        photo: this.infoUser.photo,
        firebase_id: this.infoUser.firebase_id,
        password: newpassword,
        gender: this.infoUser.gender,
        phone: this.infoUser.phone,
        birth_date: this.infoUser.birth_date,
        email_verified_at: this.infoUser.email_verified_at,
        id: this.infoUser.id
      }
      this.serviceget.updateUser(data).subscribe(userResponse => {
        if (userResponse["message"] == 'User was updated successfully.') {
          this.infoUser = {};
          this.user = {};
          this.utils.dismissLoader()
          this.dismiss();
          this.utils.toast.presentToast("Senha tualizada com sucesso!")
        } else {
          this.utils.dismissLoader()
          this.utils.toast.presentToast("Não foi possível atualizar, tente mais tarde.")
        }
      })
    }
  }

  updatedPhoto() {
    this.utils.presentLoading()
    this.infoUser.name = "" + this.name + " " + this.lastName + ""
    let data = {
      email: this.infoUser.email,
      name: this.infoUser.name,
      photo: "",
      firebase_id: this.infoUser.firebase_id,
      password: this.infoUser.password,
      gender: this.infoUser.gender,
      phone: this.infoUser.phone,
      birth_date: this.infoUser.birth_date,
      email_verified_at: this.infoUser.email_verified_at,
      id: this.infoUser.id,
      pix_code: this.infoUser.pix_code
    }

    this.serviceget.updateUser(data).subscribe(userResponse => {
      if (userResponse["message"] == 'User was updated successfully.') {
        this.infoUser = {};
        this.user = {};
        this.utils.photoProfile = ""
        this.utils.dismissLoader()
        this.dismiss();
        this.utils.toast.presentToast("Atualizado com sucesso!")
      } else {
        this.utils.toast.presentToast("Não foi possível atualizar, tente mais tarde.")
        this.utils.dismissLoader()
      }
    })
  }


  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async presentActionSheetPhoto() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Albums',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Tirar foto',
        icon: 'camera-outline',
        handler: () => {
          this.utils.openCamera("Tirar foto");
        }
      }, {
        text: 'Escolher da galeria',
        icon: 'image-outline',
        handler: () => {
          this.utils.openCamera("Galeria");
        }
      }, {
        cssClass: 'danger',
        text: 'Remover foto',
        icon: 'close-outline',
        handler: () => {
          this.updatedPhoto()
        }
      }, {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();

    const { role, data } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role and data', role, data);
  }



  /* updated password */
  async presentAlertPrompt() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Altere a sua senha',
      inputs: [
        {
          name: 'Password',
          type: 'text',
          id: 'password',
          value: '',
          placeholder: 'Digite a sua antiga senha'
        },
        {
          name: 'newPassword',
          type: 'text',
          id: 'newpassword',
          value: '',
          placeholder: 'Coloque a nova senha'
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'dark',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Confirmar',
          handler: (data) => {
            this.updatedPassword(data.Password, data.newPassword)
          }
        }
      ]
    });

    await alert.present();
  }

  saveMaskPhone(ev) {
    if (ev.detail.value.length >= 11) {
      this.infoUser.phone = this.serviceget.maskPhone(ev.detail.value);
    }
    if (ev.detail.value.length == 15) {
      this.infoUser.phone = ev.detail.value.replace(/\D+/g, '');
    }
  }


  async inactivedAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Excluir conta',
      subHeader: 'Você deseja excluir sua conta ?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'dark',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Confirmar',
          handler: (data) => {
            this.inactived()
          }
        }
      ]
    });

    await alert.present();
  }


  inactived() {
    this.serviceget.inactived(this.infoUser.id, this.artistId).then((data: any) => {
      if (data.message == "User inactived was updated successfully.") {
        this.utils.toast.presentToast("Foi excluido com sucesso.")
        this.utils.router.navigate(["/login"])
        this.dismiss()
        firebase.auth().signOut().then(() => {
          // Sign-out successful.
          this.utils.router.navigate(["/login"])
        }).catch((error) => {
          console.log(error)
        });
      } else {
        this.utils.toast.presentToast("Algo deu errado, tente mas tarde.")
      }
    }).catch(er => {
      this.utils.toast.presentToast("Algo deu errado, tente mas tarde.")
    })
  }
}

