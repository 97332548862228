export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDKdD8qmg4cUxFKMHwtyq9XwboqlRm1JBg",
    authDomain: "gigs-68666.firebaseapp.com",
    databaseURL: "https://gigs-68666.firebaseio.com",
    projectId: "gigs-68666",
    storageBucket: "gigs-68666.appspot.com",
    messagingSenderId: "425151687367",
    appId: "1:425151687367:web:660fedca96d9a13cb8ece2",
    measurementId: "G-SXMK6K1SJM"
  },

  // API NODE:
  // urlApi: 'http://localhost:3000/api_4gigs/', // API Node - Localhost
  urlApi: 'https://4gigs.devstroupe.com/api_4gigs/', // API Node - Localhost
  // urlApi: 'https://4gigs.com.br/api_4gigs/', /// API Node - Hostinger VPS

  // API PHP:
  // archiveUpload: "https://4gigs.com.br/",  // API PHP - Hostinger VPS
  // archiveUpload: "http://localhost/4gigs/4gigs-api-files/", // API localhost PHP
  archiveUpload: "https://4gigs.devstroupe.com/4gigs-api-files/", // API localhost PHP
  
  // API GATEWAY PAYMENT:
  urlPayment: "https://sandbox.boletobancario.com/", // API Gateway Payment - Juno/EBANX 

}
