import { ReceiptsComponent } from './Modal/receipts/receipts.component';
import { LoaderCustomComponent } from './pages/utilities/loader-custom/loader-custom.component';
import { AvatarCropComponent } from './components/avatar-crop/avatar-crop.component';
import { EditUserComponent } from './Modal/edit-user/edit-user.component';
import { DetailsDisputesComponent } from 'src/app/Modal/details-disputes/details-disputes.component';
import { SkeletonModule } from './pages/utilities/skeleton/skeleton.module';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, ModalController, PopoverController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Firebase Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AgmCoreModule } from '@agm/core';

// Import Calendar module
import { NgCalendarModule } from 'ionic2-calendar';

// Files and upload images modules  
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { Crop } from '@ionic-native/crop/ngx';

// Begin Facebook module
import { Facebook } from '@ionic-native/facebook/ngx';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
//payment gateway
import { Stripe } from "@ionic-native/stripe/ngx";
import { ChartsModule } from 'ng2-charts';

//IMPORT THE PLUGINS
// import { Geolocation } from '@ionic-native/geolocation/ngx';
// import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { ImageCropperComponent, ImageCropperModule } from 'ngx-image-cropper';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { GoogleMaps } from '@ionic-native/google-maps';
// Interceptors
import { environment } from 'src/environments/environment.prod';
import { BrMaskerModule } from 'brmasker-ionic-3';
import { ModalConfigComponent } from './Modal/modal-config/modal-config.component';
import { WalletComponent } from './Modal/wallet/wallet.component';
import { FormsModule } from '@angular/forms';
import { UtilsService } from './Services/utils.service';
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { ReviewsAddComponent } from 'src/app/Modal/reviews-add/reviews-add.component';

import { PausePopoverComponent } from 'src/app/popovers/pause-popover/pause-popover.component'; 
import { ProposalPopoverComponent } from './popovers/proposal-popover/proposal-popover.component';
import * as firebase from 'firebase';
import { AnimationPageModule } from './pages/animation/animation.module';

firebase.initializeApp(environment.firebase);
@NgModule({
  declarations: [
    AppComponent,
    DetailsDisputesComponent,
    ModalConfigComponent,
    WalletComponent,
    EditUserComponent,
    AvatarCropComponent,
/*     LoaderCustomComponent, */
    ReviewsComponent,
    ReviewsAddComponent,
    PausePopoverComponent,
    ProposalPopoverComponent,
    ReceiptsComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [
    DetailsDisputesComponent,
    ModalConfigComponent,
    WalletComponent,
    EditUserComponent,
    AvatarCropComponent,
/*     LoaderCustomComponent, */
    ReviewsComponent,
    ReviewsAddComponent,
    PausePopoverComponent,
    ProposalPopoverComponent,
    ReceiptsComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    ImageCropperModule ,
    ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBAzZ8Bn0fdypHgeqZ0UoGPS_GwH2tRivs'
    }),
    NgCalendarModule,
    HttpClientModule,
    BrMaskerModule,
    SkeletonModule,
    FormsModule,
    AnimationPageModule
    
  ],
  providers: [
    StatusBar,
    Stripe,
    HTTP,
    SplashScreen,
    FileTransfer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirestoreSettingsToken, useValue: {} },
    Camera,
    File,
    Crop,
    Facebook,
    InAppBrowser,
    GoogleMaps,
    UtilsService,
    ImageCropperComponent 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
