import { UploadService } from './../../Services/upload.service';
import { UtilsService } from './../../Services/utils.service';
import { GetService } from './../../Services/get.service';
import { Artist } from './../../interface/artist';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import * as moment from "moment"
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-modal-config',
  templateUrl: './modal-config.component.html',
  styleUrls: ['./modal-config.component.scss'],
})
export class ModalConfigComponent implements OnInit {
  public originName: any;
  public textok: boolean = false;
  private dateArtist: any;
  public infoUser: any = {};
  public user: Artist;
  public blockUpdated: any;

  //DADOS 
  ArtistName: any;
  biography: any;
  background: any;
  nickname: any;
  user_id: any;
  id: any;
  created: any;
  youtube: any;
  spotify: any;
  soundcloud: any;
  deezer: any;

  constructor(
    private modalController: ModalController,
    private navparams: NavParams,
    private serviceget: GetService,
    public utils: UtilsService,
  ) {
    let dados = this.navparams;
    this.infoUser = dados.data[0]
    this.infoUser = this.infoUser[0]
    this.originName = this.infoUser.nickname;
    console.log(this.infoUser);

  }

  ngOnInit() {
    this.getArtist();
  }

  EditarForm(ev, input) {
    this.infoUser[input] = ev
  }

  getArtist() {
    this.serviceget.getAllArtist().subscribe(artist => {
      this.dateArtist = artist;
    })
  }

  uploadFile(el) {
    var imgSrc = document.getElementById('blah')
    var data = new FormData
    if (el.target.files[0]) {
      data.append("fileToUpload", el.target.files[0])
    }
    this.utils.upload.uploadBackground(data).then(data => {
      console.log(data)
      if (data.status_code == 200) {
        this.infoUser.background = environment.archiveUpload + data.path
        imgSrc.setAttribute("src", "" + window.URL.createObjectURL(el.target.files[0]) + "")
        this.utils.toast.presentToast("Imagem adicionada com sucesso.");
      } else {
        imgSrc.setAttribute("src", "")
        this.utils.toast.presentToast("Algo deu errado no envio da sua imagem, verifique o formato da sua imagem ou tente enviar outra imagem.")
      }
    }).catch(err => {
      imgSrc.setAttribute("src", "")
      this.utils.toast.presentToast("Algo deu errado no envio da sua imagem!")
    })
  }

  removeImg() {
    this.infoUser.background = ""
  }


  checNickname(evt) {
    /* DOM */
    const nickname = document.getElementById("nickname")
    const checkNickName = document.getElementById("checkNickName")
    const divalertnickname = document.getElementById("divalertnickname")
    checkNickName.style.display = "block"
    /* backend */
    var check = this.dateArtist.filter(filterART => {
      return filterART.nickname == this.infoUser.nickname
    })
    /* Logic Back */
    if (evt.detail.value.length >= 4) {
      if (check == "" || check == null || check.length == 0) {
        nickname.style.color = "green"
        divalertnickname.style.color = "green"
        checkNickName.style.display = "block"
        divalertnickname.innerHTML = "Nickname Disponível"
        this.textok = false;
      } else {
        if (this.infoUser.nickname == this.originName) {
          checkNickName.style.display = "none"
          this.textok = false;
        } else {
          nickname.style.color = "red";
          divalertnickname.style.color = "red"
          divalertnickname.innerHTML = "Nickname Indisponível"
          this.textok = true;
        }
      }
    } else {
      if (evt.detail.value.length == 0) {
        this.infoUser.nickname = this.originName;
        this.textok = false;
      }
      nickname.style.color = "black";
      checkNickName.style.display = "none"
      this.infoUser.nickname = this.originName;
      this.textok = false;
    }
  }

  updateArtist() {
    this.user = {};
    this.user.ArtistName = this.infoUser.artistic_name;
    this.user.biography = this.infoUser.biography;
    this.user.background = this.infoUser.background;
    this.user.nickname = this.infoUser.nickname;
    this.user.user_id = this.infoUser.user_id;
    this.user.id = this.infoUser.id;
    this.user.created = this.infoUser.createdAt;
    this.user.youtube = this.infoUser.youtube_url;
    this.user.spotify = this.infoUser.spotify_url;
    this.user.soundcloud = this.infoUser.soundcloud_url;
    this.user.deezer = this.infoUser.deezer_url;
    this.user.updted = this.infoUser.updatedAT = moment().format("YYYY-MM-DD hh:mm:ss");
    if (this.infoUser.artistic_name == "" || this.infoUser.artistic_name == null || this.infoUser.artistic_name.length < 4) {
      this.utils.toast.presentToast("Você precisa escolher um nome para o seu artista")
    } else if (this.infoUser.biography == "" || this.infoUser.biography == null || this.infoUser.biography.length < 10) {
      this.utils.toast.presentToast("Você precisa escrever sua biografia, que tenha no mínimo 10 caracteres.")
    } else {
      var check = 1;
      if (check == 1) {
        console.log(this.infoUser);
        this.utils.upload.uploadBackground();
        this.serviceget.updateArtist(this.user).subscribe(artistADD => {
          console.log('mensagem', artistADD["message"]);
          if (artistADD["message"] == "User was updated successfully.") {
            this.infoUser = {};
            this.user = {};
            this.dismiss();
          } else {
            this.utils.toast.presentToast("Não foi possível atualizar, tente mais tarde.")
          }
        })
      } else {
        this.utils.toast.presentToast("Você precisa escolher pelo menos um perfil.")
      }
    }
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }


}
