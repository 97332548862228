import { UtilsService } from './../../Services/utils.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import * as moment from "moment"

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit {
  /* date */
  public date: any = moment().format("YYYY-MM-DD");
  public dateMax: any = moment().add(10, "year").format("YYYY-MM-DD");
  /* card */
  public cvvCard: any = "";
  public card1: any = "";
  public card2: any = "";
  public card3: any = "";
  public card4: any = "";
  public validateCard: any = "";
  public nameCard: any = ""

  /* Array and Radios */
  public list: any = [];
  public idRadios: any = '0'
  public gigsSet: any = []


  constructor(
    private modalController: ModalController,
    public utils:UtilsService
  ) { }

  ngOnInit() {
    this.cvvCard = ""
    this.card1 = ""
    this.card2 = ""
    this.card3 = ""
    this.card4 = ""
    this.validateCard = ""
    this.nameCard = ""
    this.getLocalStorage()
  }

  onGetIdRadios(el) {
    this.idRadios = el.detail.value;
  }

  getLocalStorage() {
    if (localStorage.getItem("arrayCreditCard")) {
      this.list = JSON.parse(localStorage.getItem("arrayCreditCard"));
      this.idRadios = eval("" + this.list.length + " - 1");
      this.ativedAddAddress('fab', 'block')
    }
  }

  ativedList(value, type) {
    var div = document.getElementById(value)
    div.style.display = type
    this.ativedAddAddress('fab', 'none')
  }

  ativedAddAddress(value, type) {
    if (value != 'fab') {
      this.ativedList('list', 'none')
      var div = document.getElementById(value)
      div.style.display = type
    } else {
      var div = document.getElementById(value)
      div.style.display = type
    }
  }

  cardAnimation() {
    const botaoAnimar = document.getElementById("flip-container");
    botaoAnimar.setAttribute("class", "flip")
  }
  cardAnimationInverse() {
    const botaoAnimar = document.getElementById("flip-container");
    botaoAnimar.setAttribute("class", "flipInverse")
  }

  name(el) {
    this.nameCard = el.detail.value.toUpperCase()
  }

  card(el, value) {
    switch (value) {
      case 1:
        this.card1 = el.detail.value
        break;
      case 2:
        this.card2 = el.detail.value
        break;
      case 3:
        this.card3 = el.detail.value
        break;
      case 4:
        this.card4 = el.detail.value
        break;
    }
  }

  cvv(el) {
    this.cvvCard = el.detail.value
  }


  validate(el) {
    if (el.detail.value.length > 7) {
      this.validateCard = moment(el.detail.value, "YYYY-MM-DDTHH:mm:ss+01:00").format("MM/YYYY")
    }
  }

  dismiss(value) {
    if (value) {
      this.modalController.dismiss({
        'dismissed': value[this.idRadios]
      });
    }
    this.modalController.dismiss({
      'dismissed': "false"
    });
  }


  async onAddAddress() {
    if (this.cvvCard == "" || this.cvvCard == null) {
      this.utils.toast.presentToast("Defina um CVV")
    } else if (this.card1 == "" || this.card1.length < 4) {
      this.utils.toast.presentToast("Verifique os números inseridos do cartão")
    } else if (this.card2 == "" || this.card2.length < 4) {
      this.utils.toast.presentToast("Verifique os números inseridos do cartão")
    } else if (this.card3 == "" || this.card3.length < 4) {
      this.utils.toast.presentToast("Verifique os números inseridos do cartão")
    } else if (this.card4 == "" || this.card4.length < 4) {
      this.utils.toast.presentToast("Verifique os números inseridos do cartão")
    } else if (this.validateCard == "" || this.validateCard == null) {
      this.utils.toast.presentToast("Defina uma data de vencimento")
    } else if (this.nameCard == "" || this.nameCard == null) {
      this.utils.toast.presentToast("Defina o nome que consta no cartão")
    } else {
      this.gigsSet = {
        cvvCard: this.cvvCard,
        card1: this.card1,
        card2: this.card2,
        card3: this.card3,
        card4: this.card4,
        validateCard: this.validateCard,
        nameCard: this.nameCard,
      }
      this.addLocalStorage()
    }
  }

  addLocalStorage() {
    if (this.list != null) {
      this.list = this.list.concat(this.gigsSet)
      localStorage.setItem("arrayCreditCard", JSON.stringify(this.list));
    } else {
      localStorage.setItem("arrayCreditCard", JSON.stringify([this.gigsSet]));
    }
    this.ativedAddAddress('fab', 'block')
    this.ativedAddAddress('add', 'none')
    this.ativedList('list', 'block')
    this.getLocalStorage()
  }

}
