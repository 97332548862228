
import { Injectable } from '@angular/core';
import { Menu } from '../interface/menu';

@Injectable({
    providedIn: 'root'
})

export class MenuService {

    menus: Menu[];

    constructor() { }

    listMenus() {
        return this.menus = [
            {
              title: 'Perfil',
              url: '/profile',
              icon: 'person-outline'
            },
            {
              title: 'Buscar Artistas',
              url: '/list-home',
              icon: 'search-outline'
            },
            {
              title: 'Gigs',
              url: '/gigs',
              icon: 'briefcase-outline'
            },
            {
              title: 'Relatórios',
              url: '/report',
              icon: 'bar-chart-outline'
            },
            {
              title: 'Configurações',
              url: '/configuration',
              icon: 'settings-outline'
            },
            {
              title: 'Ajuda',
              url: '/help',
              icon: 'alert-outline'
            }, 
          ]
    }
    
}