import { UtilsService } from './../../Services/utils.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { GetService } from './../../Services/get.service';
import * as moment from "moment";
import * as firebase from 'firebase';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent implements OnInit {
  public reviews: any = [];

  constructor(
    private modalController: ModalController,
    private serviceget: GetService,
    public utils: UtilsService,
    private navParams: NavParams
  ) { }

  ngOnInit(): void {
    this.getAllReviews()

  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }


  getAllReviews() {
    this.utils.presentLoading()
    console.log(this.navParams.data[0].id)
    if (this.navParams.data[0].id) var functionGet = this.serviceget.getReviewsGigs(this.navParams.data[0].id);
    if (!this.navParams.data[0].id) var functionGet = this.serviceget.getAllReviews(this.navParams.data[0][0].id);
    functionGet.then(data => {
      this.reviews = data
      console.log(data)
      this.transformArray()
      this.utils.dismissLoader()
    })

  }

  transformArray() {
    for (let i = 0; i < this.reviews.length; i++) {
      this.reviews[i].createdAt = moment(this.reviews[i].createdAt, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")
    }
  }

}


