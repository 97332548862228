// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebase: {
  //   apiKey: "AIzaSyDkgV3oT_UiVQsQQtWAEA8ybadwc2HQvl8",
  //   authDomain: "gigs-d4eb7.firebaseapp.com",
  //   databaseURL: "https://gigs-d4eb7.firebaseio.com",
  //   projectId: "gigs-d4eb7",
  //   storageBucket: "gigs-d4eb7.appspot.com",
  //   messagingSenderId: "841419817969",
  //   appId: "1:841419817969:web:6b479f9adb5d5d1d1c7a50",
  //   measurementId: "G-4PX3227P1D"
  // }
  firebase: {
    apiKey: "AIzaSyDKdD8qmg4cUxFKMHwtyq9XwboqlRm1JBg",
    authDomain: "gigs-68666.firebaseapp.com",
    databaseURL: "https://gigs-68666.firebaseio.com",
    projectId: "gigs-68666",
    storageBucket: "gigs-68666.appspot.com",
    messagingSenderId: "425151687367",
    appId: "1:425151687367:web:660fedca96d9a13cb8ece2",
    measurementId: "G-SXMK6K1SJM"
  },
  //api: 'https://www.4gigs.com.br/apis/api/v1'  
  api: 'http://localhost:3000/api_4gigs/' //// Production
  //api: 'http://localhost:8000/api/v1'       /// Local
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
