import { UtilsService } from './../../Services/utils.service';
import { formatDate } from '@angular/common';
import { UploadService } from './../../Services/upload.service';
import { GetService } from 'src/app/Services/get.service';
import { ModalController, ToastController, NavParams } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Dispute } from 'src/app/interface/dispute';
import * as firebase from 'firebase';
import * as moment from 'moment';

@Component({
  selector: 'app-details-disputes',
  templateUrl: './details-disputes.component.html',
  styleUrls: ['./details-disputes.component.scss'],
})
export class DetailsDisputesComponent implements OnInit {
  public getReasonDispute: any = [];
  public imagePreview: any = [];
  public reason_id: any
  public comment: any
  public dispute: Dispute = {}
  public dateParams: any = {}
  public formatData = new FormData;
  getDispute: any = null
  getDisputeInfo: any = null
  skeleton = false;
  setinfo = false;
  profileUser = "";
  artistComment = null;
  userID = null;
  commentArtist = null;
  constructor(
    private modalController: ModalController,
    private service: GetService,
    private navparams: NavParams,
    public utils: UtilsService,
  ) {
    this.utils.presentLoading()
  }

  ngOnInit() {
    this.dateParams = this.navparams.data[0];
    this.dateParams = [this.dateParams]
    console.log(this.dateParams)
    this.onFirebase()
    this.initCheckDispute();
  }

  /* pegando uid do firebase */
  onFirebase() {
    firebase.auth().onAuthStateChanged((usersss) => {
      if (usersss) {
        this.service.getByIdUser(usersss.uid).then(data => {
          this.dispute.user_id = data[0].id;
          this.userID = data[0].id
          this.profileUser = data[0].perfil
        })
        this.getReason();
      }
    })
  }

  momentFormatSimple(data) {
    return moment(data, "YYYY-MM-DDTHH:mm:ss.000Z").format("DD/MM/YYYY HH:mm")
  }

  initCheckDispute() {
    this.utils.dismissLoader()
    this.service.getDisputes(this.dateParams[0].id).then(data => {
      this.getDisputeInfo = data.length > 0 ? data : null
      if (this.getDisputeInfo) {
        this.service.getDisputesComments(this.getDisputeInfo[0].id).then(result => {          
          this.getDispute = result;
          this.setinfo = true;
          this.skeleton = true;
          this.filterAcceptComments(this.getDispute)
          this.utils.dismissLoader()
        }).catch(err => {
          this.skeleton = true;
          this.utils.dismissLoader()
        })

      } else {
        this.skeleton = true;
        this.utils.dismissLoader()
      }
    }).catch(err => {
      this.utils.dismissLoader()
    }
    )
  }

  filterAcceptComments(data) {
    var result = data.filter(filter => {
      return filter.function == "Artista"
    })
    this.artistComment = result.length == 0 ? result : null;
  }

  getReason() {
    this.service.getReason().then(data => {
      if (data) {
        this.getReasonDispute = data
      }
    }).catch(err => {
      console.log(err)
    })
  }

  verifyArc(ev) {
    this.imagePreview = []

    for (let i = 0; i < 5; i++) {
      var imgSrc = document.getElementById('blah' + i + '');
      imgSrc.removeAttribute('src')
    }
    if (ev.target.files.length > 5) {
      this.utils.toast.presentToast("Você pode escolher apenas 5 arquivos");
    } else {

      for (let i = 0; i < ev.target.files.length; i++) {
        this.formatData.append("fileToUpload[]", ev.target.files[i])
        this.imagePreview[i] = ev.target.files[i]
        this.imagePreview[i].id = i
        this.imagePreview[i].value = ev.target.value
        var imgSrc = document.getElementById('blah' + i + '');
        imgSrc.setAttribute("src", "" + window.URL.createObjectURL(ev.target.files[i]) + "")
      }
    }
  }

  checkData() {
    if ([null, undefined, ""].includes(this.dispute.reason_id) || [0].includes(this.dispute.reason_id.length)) {
      this.utils.toast.presentToast("Você deve informar a causa da disputa.");
    } else if ([null, undefined, ""].includes(this.dispute.comment)) {
      this.utils.toast.presentToast("Você deve explicar a causa da disputa.");
    } else if ([0].includes(this.dispute.comment.length) || this.dispute.comment.length < 50) {
      this.utils.toast.presentToast("Você deve explicar com mais informações, detalhe mais a sua disputa.");
    } else {
      this.utils.presentLoading()
      this.dispute.gig_id = this.dateParams[0].id
      this.addDispute(this.dispute)
    }
  }


  addDispute(dataCreated) {
    this.service.addDispute(dataCreated).then(data => {
      if (data.message == "Created sucessfull user") {
        const id: any = data;
        this.addDisputeSituations(dataCreated, id.data.id)
      }
    }).catch(err => {
      this.utils.dismissLoader()
    })
  }

  addDisputeSituations(dataCreated, id, status?) {
    dataCreated.dispute_id = id
    dataCreated.status_id = status ? status : 1
    this.service.addDisputeSituations(dataCreated).then(data => {
      if (data.message == "Created sucessfull user") {
        const id: any = data;
        if(dataCreated.status_id == 1){
        this.addDisputeComments(dataCreated)
      }else{
        this.ngOnInit()
        this.utils.dismissLoader()
      }
      }
    }).catch(err => {
      console.log(err)
      this.utils.dismissLoader()
    })
  }

  addDisputeComments(dataCreated) {
    this.service.addDisputeComments(dataCreated).then(data => {
      if (data.message == "Created sucessfull user") {
        const id: any = data;
        this.uploadDispute(this.formatData, id.data.id)
        this.utils.dismissLoader()
      }
    }).catch(err => {
      console.log(err)
      this.utils.dismissLoader()
    })
  }

  addDisputeCommentsOpen() {
    this.utils.presentLoading()
    if (this.dispute.comment != undefined && this.dispute.comment.length < 15) {
      // this.utils.dismissLoader();
      var prepareData: any = {};
      prepareData.comment = this.dispute.comment;
      prepareData.dispute_id = this.getDisputeInfo[0].id
      prepareData.user_id = this.userID
      this.service.addDisputeComments(prepareData).then(data => {
        if (data.message == "Created sucessfull user") {
          const id: any = data;
          this.dispute.comment = null;
          this.utils.toast.presentToast("Resposta enviado com sucesso.")
          this.addDisputeSituations(prepareData, prepareData.dispute_id, 2)
        }
      }).catch(err => {
        console.log(err)
        this.utils.dismissLoader()
      })
    } else {
      this.utils.dismissLoader()
      this.utils.dismissLoader()
      this.utils.toast.presentToast("Digite uma resposta com mais informações.")
    }
  }

  uploadDispute(formData, id) {
    this.utils.upload.uploadDispute(formData, id).then(data => {
      console.log(data)
/*       this.ngOnInit() */
    }).catch(err => {
      console.log(err)
    })
  }


  dismiss() {
    this.modalController.dismiss({
      'dismissed': "false"
    });
  }

}
