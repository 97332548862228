import { UtilsService } from './../../Services/utils.service';
import { GetService } from 'src/app/Services/get.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { review } from 'src/app/interface/gigs';

@Component({
  selector: 'app-reviews-add',
  templateUrl: './reviews-add.component.html',
  styleUrls: ['./reviews-add.component.scss'],
})
export class ReviewsAddComponent implements OnInit {
  dateParams = []
  form: review = {}
  constructor(
    private modalController: ModalController,
    private service: GetService,
    private navparams: NavParams,
    public utils:UtilsService
  ) { }

  ngOnInit() {
    this.dateParams = this.navparams.data[0];
    this.dateParams = [this.dateParams]
    console.log(this.dateParams)
  }


  dismiss() {
    this.modalController.dismiss({
      'dismissed': "false"
    });
  }



  setReview() {
    this.form.gig_id = this.dateParams[0].id
    if (this.form.rating && this.form.rating.length == 0 || [undefined, null].includes(this.form.rating)) {
      this.utils.toast.presentToast("Você precisa avaliar.")
    } else if (this.form.description && this.form.description.length == 0 || [undefined, null].includes(this.form.description)) {
      this.utils.toast.presentToast("Você precisa descrever.")
    } else {
      this.utils.presentLoading()
      this.service.addReview(this.form).then((data: any) => {
        if (data.message == "Created sucessfull user") {
          this.dismiss()
          this.utils.dismissLoader()
        }else{
          this.utils.dismissLoader()
          this.utils.toast.presentToast("Não foi possível criar, tente mais tarde.")
        }
      }).catch(err=>{
        this.utils.dismissLoader()
        this.utils.toast.presentToast("Não foi possível criar, tente mais tarde.")
      })
    }
  }
}
