import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'animation',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register-new-user',
    loadChildren: () => import('./pages/authentication/register-new-user/register-new-user.module').then( m => m.RegisterNewUserPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/authentication/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule), 
  },
  {
    path: 'list-home',
    loadChildren: () => import('./pages/home/list-home.module').then( m => m.ListHomePageModule),
  }, 
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),

  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),

  },
  {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then( m => m.ReportPageModule),
  },
  {
    path: 'gigs',
    loadChildren: () => import('./pages/gigs/gigs.module').then( m => m.GigsPageModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule),
  },
  {
    path: 'payment/:id',
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'slider-welcome',
    loadChildren: () => import('./pages/slider-Artist/slider-welcome.module').then( m => m.SliderWelcomePageModule)
  },
  //need fix
  {
    path: 'propostals/:id',
    loadChildren: () => import('./pages/propostals/propostals.module').then( m => m.PropostalsPageModule)
  },
  {
    path: 'counties',
    loadChildren: () => import('./pages/counties/counties.module').then( m => m.CountiesPageModule)
  },
  {
    path: 'order-gig/:id',
    loadChildren: () => import('./pages/order-gig/order-gig.module').then( m => m.OrderGigPageModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('./pages/configuration/configuration.module').then( m => m.ConfigurationPageModule)
  },
  {
    path: 'animation',
    loadChildren: () => import('./pages/animation/animation.module').then( m => m.AnimationPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
