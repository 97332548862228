import { PaymentControllerService } from './payment-controller.service';
import { ModalController, LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
/* import { AvatarCropComponent } from '../components/avatar-crop/avatar-crop.component'; */
import { ToastService } from './toast.service';
import { LoadingService } from './loading.service';
import { Router } from '@angular/router';
import { UploadService } from './upload.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/core';
import { AvatarCropComponent } from '../components/avatar-crop/avatar-crop.component';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  firebaseid = "";
  animation = false;
  photoProfile: any = undefined;

  constructor(
    // private camera: Camera,
    private modalCtrl: ModalController,
    private loadingCtrl:LoadingController,
    public loading:LoadingService,
    public toast:ToastService,
    public router:Router,
    public upload: UploadService,
    public paymentctrl:PaymentControllerService
  ) { }

  async dismissLoader() {
    await this.loadingCtrl.dismiss().then((response) => {
      console.log(`reponse no then ${ response }`);
      
      console.log('Loader closed!', response);
    }).catch((err) => {
       setTimeout( async () => {
        await this.loadingCtrl.dismiss().then((response) => {
          console.log(`reponse no then ${ response }`);
          console.log('Loader closed!', response);
        }).catch((err) => {
          
          console.log('Error occured : ', err);
        });
       }, 1000)
      console.log('Error occured : ', err);
    });
  }
  async presentLoading() {
   var loading = await this.loadingCtrl.create({ message: 'Aguarde...' });
    return loading.present();
  }

  async openCamera(configModeSource) {
    // const options: CameraOptions = {
    //   quality: 100,
    //   destinationType: this.camera.DestinationType.DATA_URL,
    //   encodingType: this.camera.EncodingType.PNG,
    //   sourceType: configModeSource == "Tirar foto" ? this.camera.PictureSourceType.CAMERA : this.camera.PictureSourceType.PHOTOLIBRARY,
    //   correctOrientation: true,
    // };
    // return this.camera.getPicture(options).then((imageData) => {
    //   let base64 = 'data:image/png;base64,' + imageData;
    //   this.openCropPage(base64)
    // }, (err) => {
    //      this.toast.presentToast(`<b>Sua solicitação falhou</b><br>Não foi possível realizar a sua solicitação.`);
    //   return undefined;
    // });
    const image = await Camera.getPhoto({
      quality: 90,
      source: configModeSource == "Tirar foto" ? CameraSource.Camera : CameraSource.Photos,
      allowEditing: true,
      resultType: CameraResultType.Base64
    }).then( res => {
      this.openCropPage(`data:image/png;base64,${res.base64String}`);
    }).catch( err => { this.toast.presentToast(`<b>Sua solicitação falhou</b><br>Não foi possível realizar a sua solicitação.`);
    console.error(err);
      return err;
    });
      
  }

  async openCropPage(base64) {
    const MODAL = await this.modalCtrl.create({
      component: AvatarCropComponent,
      componentProps: {
        base64,
      },
      cssClass: "modal-field",
    });

    MODAL.present(); 
  }
}
