import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient) { }

  uploadProfile(data) {
    return this.http.post(""+environment.archiveUpload+"uploadprofile.php", data).toPromise().then(data => data).catch(err => err)
  }

  uploadDispute(data,id) {
    return this.http.post(""+environment.archiveUpload+"uploaddispute.php?comments="+id+"", data).toPromise().then(data => data).catch(err => err)
  }

  uploadProposal(data) {
    return this.http.post(""+environment.archiveUpload+"uploadproposal.php", data).toPromise().then(data => data).catch(err => err)
  }
  
  uploadBackground(data?) {
    return this.http.post(""+environment.archiveUpload+"uploadbackground.php", data).toPromise().then(data => data).catch(err => err)
  }

  
}
