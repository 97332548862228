
import { UtilsService } from './../../Services/utils.service';

import { NavParams, Platform, ModalController, ActionSheetController } from '@ionic/angular';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CropperPosition, ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';


@Component({
  selector: 'app-avatar-crop',
  templateUrl: './avatar-crop.component.html',
  styleUrls: ['./avatar-crop.component.scss'],
})
export class AvatarCropComponent implements OnInit {


  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  base64;
  edit: boolean = false;
  error = false;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  position: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };
  transform: ImageTransform = {};
  rotation = 0;
  showCropper = true;
  loading = true;
  sending = false;
  constructor(
    private navParams: NavParams,
    public platform: Platform,
    private modalCtrl: ModalController,
    public utils: UtilsService,
    private actionSheetController: ActionSheetController,
   
  ) {
    this.base64 = this.navParams.get('base64');
  }

  async ngOnInit() {

  }

  async saveUserProfile(photo) {

  }


  crop(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.loading = false;
  }

  endCrop() {
    this.edit = !this.edit;
  }

  cropperReady() {
    this.loading = false;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  back() {
    this.modalCtrl.dismiss();
  }

  saveImage() {
    if (!navigator.onLine) {
      this.utils.toast.presentToast(`<b>SEM CONEXÃO</b><br>Não é possível trocar a imagem do perfil sem conexão com a internet. Por favor, verifique sua conexão e tente novamente.`);
      return;
    }
    var formdata = new FormData
    formdata.append("base64", this.croppedImage)
    formdata.append("id", this.utils.firebaseid)
    this.sending = true;
    this.utils.upload.uploadProfile(formdata).then(data => {
      console.log(data)
      if (data.status_code == 200) {
        this.utils.photoProfile = this.croppedImage;
        this.sending = false;
        this.back();
      } else {
        this.sending = false;
        this.back();
      }
      this.utils.toast.presentToast(`<b>SUCESSO</b><br>Foto de perfil atualizada com sucesso`);
    }).catch(err => {
      this.sending = false;
      this.utils.toast.presentToast(`<b>ERRO</b><br>Erro ao trocar foto do perfil`)
    })
  }

  async openAvatarSelector() {
    if (!navigator.onLine) {
      this.utils.toast.presentToast(`<b>SEM CONEXÃO</b><br>Não é possível trocar a imagem do perfil sem conexão com a internet. Por favor, verifique sua conexão e tente novamente.`);
      return;
    } else {
      this.presentActionSheet();
    }

  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Albums',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Tirar foto',
        icon: 'camera-outline',
        handler: () => {
          this.utils.openCamera("Tirar foto");
          this.back();
        }
      }, {
        text: 'Escolher da galeria',
        icon: 'image-outline',
        handler: () => {
          this.utils.openCamera("Galeria");
          this.back();
        }
      }, {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();

    const { role, data } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role and data', role, data);
  }

  setError() {
    this.error = true;
    this.utils.toast.presentToast('<b>Erro ao capturar imagem</b><br>Por favor, verifique a permissões de acesso a camera e/ou galeria e tente novamente.');
  }



}
